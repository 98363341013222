import { Droppable, Draggable } from "react-beautiful-dnd";

import styles from './styles.module.scss';

import Dropdown from 'components/Dropdown';
import DynamicRadioButtons from 'components/DynamicRadioButtons';
import Label from 'components/Label';
import TextInput from 'components/TextInput';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import SectionWithBorder from 'components/SectionWithBorder';
import ImportantText from 'components/ImportantText';
import CloseIcon from 'components/icons/CloseSubtleIcon';
import Buttonize from 'components/Buttonize';
import TextareaInput from 'components/TextareaInput';
import MoveIcon from 'components/icons/MoveIcon';
import { FormTable } from "components/form-table/form-table";

const EditFormDetailsConditionalElement = ({ element, embedded=false, formElements, index, onRegisterFormComponent, onDeleteFormComponent, setFormElements }) => {

    const handleRemoveElement = (_, element) => {
        const elementIndex = formElements.findIndex(elements => elements?.questionId === element?.questionId);
        const deletedElement = formElements[elementIndex];
        const formComponent = deletedElement?.formComponent;
        const formIndex = deletedElement?.index;
        const formElementsToModify = [...formElements];
        formElementsToModify.splice(elementIndex, 1);
        const changedElements = [...formElementsToModify];

        const newFormElements = [];
        for (let i = 0; i < changedElements.length; i++) {
            const newElement = {...changedElements[i]};
            if(newElement['formComponent'] === formComponent){
                if(newElement['index'] > formIndex){
                    newElement['index'] = newElement['index'] - 1;
                }
            }
            newFormElements.push(newElement);
        }
        setFormElements(newFormElements);
    };

    const style = embedded ? styles.embededConditional : styles.conditionalsContainer

    return (
            <div className={style}>
                {element.choices && element.choices.map((option, _index) => {
                    const optionValue = option?.value ? option?.value : option?.name;
                    return(
                        <SectionWithBorder className={styles.sectionWithBorder}>
                            <ImportantText text={option?.label} />

                            <Droppable
                                droppableId={`conditionalQuestionsFor_${element?.questionId}_${optionValue}`}
                                key={`conditionalQuestionsFor_${element?.questionId}_${optionValue}`}
                            >
                                {(embeddedProvided, snapshot) => {
                                    return (
                                        <div
                                            {...embeddedProvided.droppableProps}
                                            ref={embeddedProvided.innerRef}
                                            style={{
                                                background: snapshot.isDraggingOver
                                                    ? "lightgrey"
                                                    : "inherit",
                                                left: 'auto !important',
                                                minHeight: 200,
                                                top: 'auto !important'
                                            }}
                                        >
                                            {formElements.map((subElement, _subIndex) => {
                                                return(
                                                <Draggable
                                                    draggableId={subElement.questionId}
                                                    index={index}
                                                    key={subElement.questionId}
                                                >
                                                    {(embeddedProvided) => {
                                                        if(subElement?.formComponent === `conditionalQuestionsFor_${element?.questionId}_${optionValue}`){
                                                            embeddedProvided.dragHandleProps["data-rbd-drag-handle-draggable-id"] = subElement?.questionId;
                                                            embeddedProvided.draggableProps["data-rbd-draggable-id"] = subElement?.questionId;
                                                            return (
                                                                <div
                                                                    ref={embeddedProvided.innerRef}
                                                                    {...embeddedProvided.draggableProps}
                                                                    data-rbd-draggable-id={subElement.id}
                                                                    style={{
                                                                        padding: 16,
                                                                        userSelect: "none",
                                                                        ...embeddedProvided.draggableProps.style
                                                                    }}
                                                                >
                                                                    {subElement.type === 'title' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>       
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>     

                                                                            <h2>{subElement.name}</h2>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'text' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>   
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>     
                                                                                      
                                                                            <p>{subElement.name}</p>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'textInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>     

                                                                            <TextInput
                                                                                label={subElement.name}
                                                                            />
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'textAreaInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>     

                                                                            <TextareaInput
                                                                                label={subElement.name}
                                                                            />

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'radioInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>       
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>          

                                                                            <div>
                                                                                <Label text={subElement.name} />
            
                                                                                <DynamicRadioButtons
                                                                                    className={styles.radioButtons} 
                                                                                    items={subElement.choices}
                                                                                />
                                                                            </div>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'dropDownInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>     
                                                                            
                                                                            <Dropdown items={subElement.choices} label={subElement.name} />
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {subElement.type === 'checkBoxInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>            
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>     

                                                                            <>
                                                                                <Label text={subElement.name} />
            
                                                                                {subElement.choices.map((option, _index) => {
                                                                                    return(
                                                                                        <CheckboxWithLabel text={option.label} />
                                                                                    );
                                                                                })}
                                                                            </>
            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }

                                                                    {subElement.type === 'conditional' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>     

                                                                            <h4>Conditional on field '{subElement.name}'</h4>

                                                                            <div></div>

                                                                            <EditFormDetailsConditionalElement element={subElement} embedded={true} formElements={formElements} index={index} setFormElements={setFormElements} />

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, subElement)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }

                                                                    {subElement.type === 'table' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...embeddedProvided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <FormTable editColumns editRows onFormComponentRef={onRegisterFormComponent(subElement.id)} />
                                                                            
                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => {
                                                                                    onDeleteFormComponent(subElement.id);
                                                                                    handleRemoveElement(res, index)}}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }else{
                                                            return(
                                                                <div
                                                                    ref={embeddedProvided.innerRef}
                                                                >
                                                                </div>
                                                            )
                                                        }
                                                    }}
                                                </Draggable>
                    )})}

                                            {embeddedProvided.placeholder}
                                        </div>
                                    )
                                }}   
                            </Droppable>
                        </SectionWithBorder>
                    );
                })}
            </div>
    );
};

export default EditFormDetailsConditionalElement;
