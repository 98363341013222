import AddActivityButton from 'components/AddActivityButton';
import WithDownloadButton from 'components/WithDownloadButtonActivities';
import WithPageTitle from 'components/WithPageTitle';

import translations from './constants';

import CheckMissingPermissions from 'components/CheckMissingPermissions';
import CheckPermissions from 'components/CheckPermissions';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useVariables } from 'hooks/variables.hook';
import { toDateString } from 'utilities/common.util';
import { ActivitiesPanel } from './components/activities-panel.component';
import styles from './styles.module.scss';
import { gqlSortTypes } from 'utilities/gql/gql.util';
import { getActivityDateFilter } from 'utilities/activities/filters';

const initActivityFilters = () => {
    const { activityDateFrom, activityDateTo } = getActivityDateFilter();

    return {
        activityAssignees: [],
        activityDueDateFrom: toDateString(activityDateFrom),
        activityDueDateTo: toDateString(activityDateTo),
        activityName: undefined,
        activityRecurrence: [],
        activityReviewers: [],
        activityStatus: [],
        activityTypes: [],
        entityName: [],
        isFlagged: false
    };
};

const Activities = () => {
    const { ACTIVITIES } = translations();
    const { useIsAllFilter, useTenants, useUserId } = useGlobalStateHooks();
    const [isAll, setIsAll] = useIsAllFilter();
    const [schemas] = useTenants();
    const [userIdStr] = useUserId();
    const userId = parseInt(userIdStr);

    const filtersState = useVariables(initActivityFilters);
    const [filters, setFilters] = filtersState;

    const isAssignedToMe = !isAll;
    const isMultiTenant = schemas.length > 1;
    const computedFilters = { ...filters, isAssignedToMe, schemas, userId };

    const queriesFilters = {
        completed: {
            ...computedFilters,
            activityStatus: ['completed']
        },
        global: computedFilters,
        overdue: {
            ...computedFilters,
            activityDueDateFrom: undefined,
            activityDueDateTo: undefined,
            activityStatus: ['overdue'],
            dueDateSort: gqlSortTypes.ASC
        },
        pending: {
            ...computedFilters,
            activityDueDateFrom: undefined,
            activityDueDateTo: undefined,
            activityStatus: ['pending'],
            dueDateSort: gqlSortTypes.ASC
        },
        upcoming: {
            ...computedFilters,
            activityStatus: ['upcoming'],
            dueDateSort: gqlSortTypes.ASC
        }
    };

    return (
        <div className={styles.activities}>
            <WithPageTitle title={ACTIVITIES}>
                <WithDownloadButton
                    filters={queriesFilters}
                    hasPDFexport={false}
                    name={'Activities'}
                >
                    <CheckPermissions permission={'activity_add'}>
                        <AddActivityButton />
                    </CheckPermissions>
                </WithDownloadButton>
            </WithPageTitle>

            <CheckPermissions permission={'activity_view'}>
                <ActivitiesPanel
                    className={styles.activitiesPanel}
                    filtersState={[queriesFilters, setFilters]}
                    isMultiTenant={isMultiTenant}
                    onTogleIsMe={() => setIsAll(value => !value)}
                    schemas={schemas}
                />
            </CheckPermissions>

            <CheckMissingPermissions
                permission={[
                    'activity_add',
                    'activity_delete',
                    'activity_edit',
                    'activity_view'
                ]}
                text={'activity'}
            />
        </div>
    );
};

export default Activities;
