import { gql, useApolloClient } from '@apollo/client';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import state from 'app/state';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useGraphQL from 'hooks/useGraphQL';
import {
    draftUniqueRecurrenceDates,
    getLastDueDate,
    updateRecurrenceDates
} from 'utilities/recurrence-dates';

const { updateActivityRecurrence } = state;

const { updateActivityRecurrenceAtom } = updateActivityRecurrence.atoms;

const MUTATE_UPSERT_ACTIVITY_WITH_RECURRENCE = ({
    insertActivities,
    tenantSchema,
    updateActivities
}) => gql`
  mutation upsertActivityWithRecurrence(${[
      insertActivities
          ? `$insertActivities: [${tenantSchema}_activity_insert_input!]!`
          : undefined,
      updateActivities
          ? `$updateActivities: [${tenantSchema}_activity_updates!]!`
          : undefined
  ]
      .filter(Boolean)
      .join(', ')}){
      ${
          insertActivities
              ? `insert_${tenantSchema}_activity(objects: $insertActivities){ returning { id } }`
              : ''
      }
      ${
          updateActivities
              ? `update_${tenantSchema}_activity_many(updates: $updateActivities){ returning { id } }`
              : ''
      }
  }
`;

const MUTATE_UPDATE_ACTIVITY_RECURRENCE = tenantSchema => gql`
  mutation updateActivityRecurrence(
        $activityRecurrenceId: Int!,
        $activity: String,
        $activity_type: Int,
        $hierarchy: Boolean!,
        $instructions: String,
        $recurrence: String, 
        $sendEmail: Boolean,
        $start_date: Int
    ){
    update_${tenantSchema}_activity_recurrence(
      where: {
        id: {_eq: $activityRecurrenceId}
      },
      _set: {
        start_date: $start_date,
        activity_type_id: $activity_type,
        instructions: $instructions,
        is_hierarchical: $hierarchy,
        name: $activity,
        recurrence_type: $recurrence,
        send_email: $sendEmail
      }
    ){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_RECURRENCE = tenantSchema => gql`
  mutation insertActivityRecurrence(
        $activity: String,
        $activity_type: Int,
        $hierarchy: Boolean!,
        $instructions: String,
        $recurrence: String, 
        $sendEmail: Boolean,
        $start_date: Int
    ){
    insert_${tenantSchema}_activity_recurrence(
      objects: {
        start_date: $start_date,
        activity_type_id: $activity_type,
        instructions: $instructions,
        is_hierarchical: $hierarchy,
        name: $activity,
        recurrence_type: $recurrence,
        send_email: $sendEmail
      }
    ){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_RECURRENCES = tenantSchema => gql`
  mutation insertActivityRecurrences(
        $activityId: Int!,
        $activityRecurrenceId: Int!
    ){
    insert_${tenantSchema}_activity_recurrences(
      objects: {
        activity_id: $activityId,
        activity_recurrence_id: $activityRecurrenceId
      }
    ){
        returning {
            id
        }
    }
  }
`;

const MUTATE_UPDATE_ACTIVITY = tenantSchema => gql`
  mutation updateActivityRecurrence(
        $activityId: Int!,
        $activity: String,
        $activity_type: Int,
        $hierarchy: Boolean!,
        $instructions: String,
        $sendEmail: Boolean!
    ){
    update_${tenantSchema}_activity(
      where: {
        id: {_eq: $activityId}
      },
      _set: {
        name: $activity,
        activity_type_id: $activity_type,
        instructions: $instructions,
        is_hierarchical: $hierarchy,
        send_email: $sendEmail
      }
    ){
        returning {
            id
        }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_RECURRENCE_ENTITIES = tenantSchema => gql`
  mutation deleteActivityRecurrenceEntity(
        $activityRecurrenceId: Int!
    ){
    delete_${tenantSchema}_activity_recurrence_entity(
      where: {
        activity_recurrence_id: {_eq: $activityRecurrenceId}
      }){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_RECURRENCE_ENTITIES = tenantSchema => gql`
  mutation insertActivityRecurrenceEntity(
        $activityRecurrenceId: Int!
        $entity: Int!
    ){
    insert_${tenantSchema}_activity_recurrence_entity(
      objects: {
        activity_recurrence_id: $activityRecurrenceId,
        entity_id: $entity
      }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_ENTITIES = tenantSchema => gql`
  mutation deleteActivityEntity(
        $activityId: Int!
    ){
    delete_${tenantSchema}_activity_entity(
      where: {
        activity_id: {_eq: $activityId}
      }){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_ENTITIES = tenantSchema => gql`
  mutation insertActivityEntity(
        $activityId: Int!
        $entity: Int!
    ){
    insert_${tenantSchema}_activity_entity(
      objects: {
        activity_id: $activityId,
        entity_id: $entity
      }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_RECURRENCE_POLICIES = tenantSchema => gql`
  mutation deleteActivityRecurrencePolicies(
        $activityRecurrenceId: Int!
    ){
    delete_${tenantSchema}_activity_recurrence_policies(
      where: {
        activity_recurrence_id: {_eq: $activityRecurrenceId}
      }){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_RECURRENCE_POLICIES = tenantSchema => gql`
  mutation insertActivityRecurrencePolicy(
        $activityRecurrenceId: Int!
        $policy: Int!
        $section: Int
    ){
    insert_${tenantSchema}_activity_recurrence_policies(
      objects: {
        activity_recurrence_id: $activityRecurrenceId,
        document_mapping_id: $policy,
        document_section_id: $section
      }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_POLICIES = tenantSchema => gql`
  mutation deleteActivityPolicies(
        $activityId: Int!
    ){
    delete_${tenantSchema}_activity_policies(
      where: {
        activity_id: {_eq: $activityId}
      }){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_POLICIES = tenantSchema => gql`
  mutation insertActivityPolicy(
        $activityId: Int!
        $policy: Int!
        $section: Int!
    ){
    insert_${tenantSchema}_activity_policies(
      objects: {
        activity_id: $activityId,
        document_mapping_id: $policy,
        document_section_id: $section
      }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_RECURRENCE_USERS = tenantSchema => gql`
  mutation deleteActivityRecurrenceUsers(
        $activityRecurrenceId: Int!
        $assignmentType: String!
    ){
    delete_${tenantSchema}_user_activity_recurrence(where:{
      _and:{
        activity_recurrence_id: {_eq: $activityRecurrenceId},
        assignment_type: {_eq: $assignmentType}
      }
    }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_RECURRENCE_USERS = tenantSchema => gql`
  mutation insertActivityRecurrencePolicy(
        $activityRecurrenceId: Int!
        $assignmentType: String!
        $groupId: Int
        $userId: Int
    ){
    insert_${tenantSchema}_user_activity_recurrence(
      objects: {
        activity_recurrence_id: $activityRecurrenceId,
        assignment_type: $assignmentType,
        group_id: $groupId,
        user_id: $userId
      }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_USERS = tenantSchema => gql`
  mutation deleteActivityUsers(
        $activityId: Int!
        $assignmentType: String!
    ){
    delete_${tenantSchema}_user_activity(where:{
      _and:{
        activity_id: {_eq: $activityId},
        assignment_type: {_eq: $assignmentType}
      }
    }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_USERS = tenantSchema => gql`
  mutation insertActivityUsers(
        $activityId: Int!
        $assignmentType: String!
        $groupId: Int
        $userId: Int
    ){
    insert_${tenantSchema}_user_activity(
      objects: {
        activity_id: $activityId,
        assignment_type: $assignmentType,
        group_id: $groupId,
        user_id: $userId
      }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_RECURRENCE_FORMS = tenantSchema => gql`
  mutation deleteActivityRecurrenceForms(
        $activityRecurrenceId: Int!
    ){
    delete_${tenantSchema}_activity_recurrence_forms(
      where: {
        activity_recurrence_id: {_eq: $activityRecurrenceId}
      }){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_RECURRENCE_FORMS = tenantSchema => gql`
  mutation insertActivityRecurrenceForms(
        $activityRecurrenceId: Int!
        $assignment: String!
        $assignmentType: String!
        $dueDate: Int!
        $formId: Int!
    ){
    insert_${tenantSchema}_activity_recurrence_forms(
      objects: {
        activity_recurrence_id: $activityRecurrenceId,
        form_id: $formId,
        assignment: $assignment,
        assignment_type: $assignmentType,
        due_date: $dueDate
      }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_DELETE_ACTIVITY_FORMS = tenantSchema => gql`
  mutation deleteActivityForms(
        $activityId: Int!
    ){
    delete_${tenantSchema}_activity_forms(
      where: {
        activity_id: {_eq: $activityId}
      }){
        returning {
            id
        }
    }
  }
`;

const MUTATE_INSERT_ACTIVITY_FORMS = tenantSchema => gql`
  mutation insertActivityForms(
        $activityId: Int!
        $assignment: String!
        $assignmentType: String!
        $dueDate: timestamp!
        $formId: Int!
    ){
    insert_${tenantSchema}_activity_forms(
      objects: {
        activity_id: $activityId,
        form_id: $formId,
        assignment: $assignment,
        assignment_type: $assignmentType,
        due_date: $dueDate
      }) {
      returning {
        id
      }
    }
  }
`;

const useData = providedSchema => {
    const client = useApolloClient();

    const mutation = useGraphQL(
        MUTATE_UPDATE_ACTIVITY_RECURRENCE,
        providedSchema
    );
    const mutationInsertRecurrence = useGraphQL(
        MUTATE_INSERT_ACTIVITY_RECURRENCE,
        providedSchema
    );
    const mutationInsertRecurrences = useGraphQL(
        MUTATE_INSERT_ACTIVITY_RECURRENCES,
        providedSchema
    );
    const mutationActivity = useGraphQL(MUTATE_UPDATE_ACTIVITY, providedSchema);
    const mutationDeleteRecurrenceEntities = useGraphQL(
        MUTATE_DELETE_ACTIVITY_RECURRENCE_ENTITIES,
        providedSchema
    );
    const mutationInsertRecurrenceEntities = useGraphQL(
        MUTATE_INSERT_ACTIVITY_RECURRENCE_ENTITIES,
        providedSchema
    );
    const mutationDeleteEntities = useGraphQL(
        MUTATE_DELETE_ACTIVITY_ENTITIES,
        providedSchema
    );
    const mutationInsertEntities = useGraphQL(
        MUTATE_INSERT_ACTIVITY_ENTITIES,
        providedSchema
    );
    const mutationDeleteRecurrencePolicies = useGraphQL(
        MUTATE_DELETE_ACTIVITY_RECURRENCE_POLICIES,
        providedSchema
    );
    const mutationInsertRecurrencePolicies = useGraphQL(
        MUTATE_INSERT_ACTIVITY_RECURRENCE_POLICIES,
        providedSchema
    );
    const mutationDeletePolicies = useGraphQL(
        MUTATE_DELETE_ACTIVITY_POLICIES,
        providedSchema
    );
    const mutationInsertPolicies = useGraphQL(
        MUTATE_INSERT_ACTIVITY_POLICIES,
        providedSchema
    );
    const mutationDeleteRecurrenceUsers = useGraphQL(
        MUTATE_DELETE_ACTIVITY_RECURRENCE_USERS,
        providedSchema
    );
    const mutationInsertRecurrenceUsers = useGraphQL(
        MUTATE_INSERT_ACTIVITY_RECURRENCE_USERS,
        providedSchema
    );
    const mutationDeleteUsers = useGraphQL(
        MUTATE_DELETE_ACTIVITY_USERS,
        providedSchema
    );
    const mutationInsertUsers = useGraphQL(
        MUTATE_INSERT_ACTIVITY_USERS,
        providedSchema
    );
    const mutationDeleteRecurrenceForms = useGraphQL(
        MUTATE_DELETE_ACTIVITY_RECURRENCE_FORMS,
        providedSchema
    );
    const mutationInsertRecurrenceForms = useGraphQL(
        MUTATE_INSERT_ACTIVITY_RECURRENCE_FORMS,
        providedSchema
    );
    const mutationDeleteForms = useGraphQL(
        MUTATE_DELETE_ACTIVITY_FORMS,
        providedSchema
    );
    const mutationInsertForms = useGraphQL(
        MUTATE_INSERT_ACTIVITY_FORMS,
        providedSchema
    );

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const updateActivityRecurrence = useRecoilCallback(
        ({ set }) =>
            variables => {
                async function upsertRecurrenceActivities({
                    activityName,
                    activityTypeId,
                    activityDueDate,
                    activityEntities = [],
                    activityUsers = [],
                    activityPolicies = [],
                    activityForms = [],
                    activityRecurrenceId,
                    daysBeforeDueDate,
                    groupCompletionRate,
                    instructions,
                    isHierarchical,
                    policyId,
                    recurrences = [],
                    recurrenceType,
                    sendEmail,
                    tenantSchema
                }) {
                    const dueDate = new Date(activityDueDate);
                    const lastDueDate = getLastDueDate({
                        dueDate,
                        recurrenceType
                    });
                    const isMarkedAsDeleted = recurrenceType === 'none';

                    const recurrenceToUpdate = isMarkedAsDeleted
                        ? recurrences
                        : updateRecurrenceDates({
                              daysBeforeDueDate,
                              dueDate,
                              recurrenceType,
                              recurrences
                          });

                    const recurrenceToInsert = (
                        isMarkedAsDeleted
                            ? []
                            : draftUniqueRecurrenceDates({
                                  daysBeforeDueDate,
                                  endDate: lastDueDate,
                                  greaterThanDueDate:
                                      recurrenceToUpdate.at(-1)?.dueDate,
                                  recurrenceType,
                                  startDate: dueDate
                              })
                    ).filter(
                        ({ dueDate }) =>
                            !recurrenceToUpdate.some(
                                r =>
                                    new Date(r.dueDate).getTime() ===
                                    new Date(dueDate).getTime()
                            )
                    );

                    await client.mutate({
                        mutation: MUTATE_UPSERT_ACTIVITY_WITH_RECURRENCE({
                            tenantSchema,
                            insertActivities: recurrenceToInsert.length > 0,
                            updateActivities: recurrenceToUpdate.length > 0
                        }),
                        variables: {
                            insertActivities: recurrenceToInsert.map(
                                ({ dueDate, startDate }) => ({
                                    activity_type_id: activityTypeId,
                                    dropdown_selections: {},
                                    due_date: dueDate,
                                    group_completion_rate: groupCompletionRate,
                                    instructions,
                                    is_hierarchical: isHierarchical,
                                    name: activityName,
                                    policy_id: policyId,
                                    start_date: startDate,
                                    send_email: sendEmail,
                                    activity_recurrences: {
                                        data: {
                                            activity_recurrence_id:
                                                activityRecurrenceId
                                        }
                                    },
                                    ...(activityEntities.length > 0 && {
                                        activity_entities: {
                                            data: activityEntities.map(
                                                entityId => ({
                                                    entity_id: Number(entityId)
                                                })
                                            )
                                        }
                                    }),
                                    ...(activityUsers.length > 0 && {
                                        user_activities: {
                                            data: activityUsers.map(
                                                ({
                                                    assignmentType,
                                                    type,
                                                    value
                                                }) => ({
                                                    assignment_type:
                                                        assignmentType,
                                                    group_id:
                                                        type === 'group'
                                                            ? Number(value)
                                                            : null,
                                                    user_id:
                                                        type === 'user'
                                                            ? Number(value)
                                                            : null
                                                })
                                            )
                                        }
                                    }),
                                    ...(activityPolicies.length > 0 && {
                                        activity_policies: {
                                            data: activityPolicies.map(
                                                ({ policy, section }) => ({
                                                    document_mapping_id: policy,
                                                    document_section_id: section
                                                })
                                            )
                                        }
                                    }),
                                    ...(activityForms.length > 0 && {
                                        activity_forms: {
                                            data: activityForms.map(
                                                ({
                                                    assignee,
                                                    assignment,
                                                    dueDate,
                                                    formId,
                                                    id
                                                }) => {
                                                    const formDueDate =
                                                        new Date(startDate);
                                                    formDueDate.setDate(
                                                        formDueDate.getDate() +
                                                            Number(dueDate)
                                                    );
                                                    return {
                                                        assignment:
                                                            assignee ??
                                                            assignment,
                                                        assignment_type: '1',
                                                        due_date: formDueDate,
                                                        form_id: formId ?? id,
                                                    };
                                                }
                                            )
                                        }
                                    })
                                })
                            ),
                            updateActivities: recurrenceToUpdate.map(
                                ({ dueDate, id, startDate }) => ({
                                    where: {
                                        id: { _eq: id }
                                    },
                                    _set: {
                                        ...(isMarkedAsDeleted ||
                                        dueDate > lastDueDate
                                            ? {
                                                  deleted: true
                                              }
                                            : {
                                                  due_date: dueDate,
                                                  start_date: startDate
                                              })
                                    }
                                })
                            )
                        }
                    });
                }

                const runMutation = async () => {
                    const groupCompletionRate = [];

                    let assignmentCategoryCount = 0;

                    for (var key in variables) {
                        if (
                            key.includes('assignee') ||
                            key.includes('reviewer')
                        ) {
                            assignmentCategoryCount++;
                        }
                    }
                    for (var i = 0; i < assignmentCategoryCount; i++) {
                        groupCompletionRate.push(1);
                    }

                    var activityRecurrenceId;

                    if (variables?.activityRecurrenceId) {
                        const res = await mutation(variables);
                        activityRecurrenceId =
                            res?.data[
                                `update_${usedSchema}_activity_recurrence`
                            ]?.returning[0]?.id;
                    } else {
                        const res = await mutationInsertRecurrence(variables);
                        activityRecurrenceId =
                            res?.data[
                                `insert_${usedSchema}_activity_recurrence`
                            ]?.returning[0]?.id;
                        variables.activityRecurrenceId = activityRecurrenceId;
                        await mutationInsertRecurrences({
                            activityId: variables?.activity_id,
                            activityRecurrenceId: activityRecurrenceId
                        });
                    }

                    for (const activityId of variables?.recurrencesForUpdate) {
                        await mutationActivity({ activityId, ...variables });
                    }

                    if (variables?.entityRun) {
                        await mutationDeleteRecurrenceEntities(variables);
                        const splitEntities = Array.isArray(variables?.entity)
                            ? variables?.entity
                            : variables?.entity.split(',');
                        const runEntities = splitEntities.filter(function (e) {
                            return e !== null && e !== '';
                        });
                        if (
                            Array.isArray(runEntities) &&
                            runEntities.length > 0
                        ) {
                            for (const entity of runEntities) {
                                await mutationInsertRecurrenceEntities({
                                    activityRecurrenceId:
                                        variables?.activityRecurrenceId,
                                    entity: Number(entity)
                                });
                            }
                        }
                        for (const activityId of variables?.recurrencesForUpdate) {
                            await mutationDeleteEntities({ activityId });

                            if (
                                Array.isArray(runEntities) &&
                                runEntities.length > 0
                            ) {
                                for (const entity of runEntities) {
                                    await mutationInsertEntities({
                                        activityId,
                                        entity: Number(entity)
                                    });
                                }
                            }
                        }
                    }

                    if (variables?.policiesRun) {
                        await mutationDeleteRecurrencePolicies(variables);
                        for (const activityId of variables?.recurrencesForUpdate) {
                            await mutationDeletePolicies({ activityId });
                        }
                        const runPolicies = variables?.policies.filter(
                            e => e !== ''
                        );
                        if (
                            Array.isArray(runPolicies) &&
                            runPolicies.length > 0
                        ) {
                            for (const policy of variables?.policies) {
                                const idArray = policy.split('.');
                                const policyId = Number(idArray[0]);
                                const sectionId =
                                    idArray[1] === '0'
                                        ? null
                                        : Number(idArray[1]);
                                await mutationInsertRecurrencePolicies({
                                    activityRecurrenceId:
                                        variables?.activityRecurrenceId,
                                    policy: policyId,
                                    section: sectionId
                                });
                                for (const activityId of variables?.recurrencesForUpdate) {
                                    await mutationInsertPolicies({
                                        activityId,
                                        policy: policyId,
                                        section: sectionId
                                    });
                                }
                            }
                        }
                    }

                    if (variables?.usersRun) {
                        for (const groups of variables?.modifiedGroups) {
                            await mutationDeleteRecurrenceUsers({
                                activityRecurrenceId:
                                    variables?.activityRecurrenceId,
                                assignmentType: groups
                            });
                            for (const activityId of variables?.recurrencesForUpdate) {
                                await mutationDeleteUsers({
                                    activityId,
                                    assignmentType: groups
                                });
                            }
                        }
                        const runUsers = variables?.users.filter(
                            u => u.value !== 0
                        );
                        if (Array.isArray(runUsers) && runUsers.length > 0) {
                            for (const user of variables?.users) {
                                if(!user?.assignmentType){
                                    continue
                                }
                                await mutationInsertRecurrenceUsers({
                                    activityRecurrenceId:
                                        variables?.activityRecurrenceId,
                                    assignmentType: user?.assignmentType,
                                    groupId:
                                        user?.type === 'group'
                                            ? Number(user?.value)
                                            : null,
                                    userId:
                                        user?.type === 'user'
                                            ? Number(user?.value)
                                            : null
                                });
                                for (const activityId of variables?.recurrencesForUpdate) {
                                    await mutationInsertUsers({
                                        activityId,
                                        assignmentType: user?.assignmentType,
                                        groupId:
                                            user?.type === 'group'
                                                ? Number(user?.value)
                                                : null,
                                        userId:
                                            user?.type === 'user'
                                                ? Number(user?.value)
                                                : null
                                    });
                                }
                            }
                        }
                    }

                    if (variables?.formsRun) {
                        await mutationDeleteRecurrenceForms(variables);
                        for (const activityId of variables?.recurrencesForUpdate) {
                            await mutationDeleteForms({ activityId });
                        }
                        if (
                            Array.isArray(variables?.forms) &&
                            variables?.forms.length > 0
                        ) {
                            for (const form of variables?.forms) {
                                await mutationInsertRecurrenceForms({
                                    activityRecurrenceId:
                                        variables?.activityRecurrenceId,
                                    assignment: form?.assignee
                                        ? form?.assignee
                                        : form?.assignment,
                                    assignmentType: '1',
                                    dueDate: Number(form?.dueDate),
                                    formId: form?.formId
                                        ? form?.formId
                                        : form?.id
                                });
                                for (const activityId of variables?.recurrencesForUpdate) {
                                    const formDueDate =
                                        variables?.recurrences &&
                                        variables?.recurrences.filter(
                                            r => r.id === activityId
                                        )[0]?.startDate
                                            ? new Date(
                                                  variables?.recurrences.filter(
                                                      r => r.id === activityId
                                                  )[0]?.startDate
                                              )
                                            : new Date();
                                    formDueDate.setDate(
                                        formDueDate.getDate() +
                                            Number(form?.dueDate)
                                    );
                                    await mutationInsertForms({
                                        activityId,
                                        assignment: form?.assignee
                                            ? form?.assignee
                                            : form?.assignment,
                                        assignmentType: '1',
                                        dueDate: formDueDate,
                                        formId: form?.formId
                                            ? form?.formId
                                            : form?.id
                                    });
                                }
                            }
                        }
                    }

                    if (variables?.recurrenceRun || variables?.startDateRun) {
                        const activtyUsers = variables?.users.filter(
                            u => u.value !== 0
                        );
                        const activityPolicies = variables?.policies
                            .filter(e => e !== '')
                            .map(e => {
                                const [policy, section] = e.split('.');
                                return {
                                    policy: Number(policy),
                                    section:
                                        section === '0' ? null : Number(section)
                                };
                            });

                        const activityEntities = (
                            (Array.isArray(variables?.entity)
                                ? variables?.entity
                                : variables?.entity.split(',')) ?? []
                        ).filter(e => e !== null && e !== '');

                        await upsertRecurrenceActivities({
                            activityEntities,
                            activityForms: variables?.forms ?? [],
                            activityPolicies: activityPolicies ?? [],
                            activityUsers: activtyUsers ?? [],
                            activityRecurrenceId,
                            recurrenceType: variables?.recurrence,
                            recurrences: variables?.recurrences ?? [],
                            activityDueDate: variables?.currentActivityEndDate,
                            daysBeforeDueDate: variables?.start_date,
                            activityName: variables?.activity,
                            activityTypeId: variables?.activity_type,
                            groupCompletionRate: variables?.groupCompletionRate,
                            instructions: variables?.instructions,
                            isHierarchical: variables?.hierarchy,
                            policyId: variables?.policy,
                            sendEmail: variables?.sendEmail,
                            tenantSchema: usedSchema
                        });
                    }

                    set(updateActivityRecurrenceAtom, activityRecurrenceId);

                    if (variables.onSuccess) {
                        variables.onSuccess();
                    }
                };

                runMutation();
            }
    );

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateActivityRecurrenceAtom, '');
    });

    return [
        useRecoilValue(updateActivityRecurrenceAtom),
        updateActivityRecurrence,
        reset
    ];
};

export default useData;
