import { addDaysToDate } from './date';

const defaultRecurrenceType = 'none';

export const getNextDateGenerator = {
    annually: getNextAnnualDate,
    biweekly: getNextBiWeekDate,
    daily: getNextDayDate,
    monthly: getNextMonthDate,
    none: () => undefined,
    quarterly: getNextQuarterDate,
    semi_annual: getNextSemiAnnualDate,
    weekly: getNextWeekDate
};

function getNextDayDate(date, unit = 1) {
    const nextDayDate = new Date(date);
    nextDayDate.setDate(nextDayDate.getDate() + 1 * unit);
    return nextDayDate;
}

function getNextWeekDate(date, unit = 1) {
    const nextWeekDate = new Date(date);
    nextWeekDate.setDate(nextWeekDate.getDate() + 7 * unit);
    return nextWeekDate;
}

function getNextBiWeekDate(date, unit = 1) {
    const nextBiWeekDate = new Date(date);
    nextBiWeekDate.setDate(nextBiWeekDate.getDate() + 14 * unit);
    return nextBiWeekDate;
}

function getNextMonthDate(date, unit = 1) {
    const nextMonthDate = new Date(date);
    return toValidDate({
        day: nextMonthDate.getDate(),
        month: nextMonthDate.getMonth() + 1 * unit,
        year: nextMonthDate.getFullYear()
    });
}

function getNextQuarterDate(date, unit = 1) {
    const nextQuarterDate = new Date(date);
    return toValidDate({
        day: nextQuarterDate.getDate(),
        month: nextQuarterDate.getMonth() + 3 * unit,
        year: nextQuarterDate.getFullYear()
    });
}

function getNextSemiAnnualDate(date, unit = 1) {
    const nextSemiAnnualDate = new Date(date);

    return toValidDate({
        day: nextSemiAnnualDate.getDate(),
        month: nextSemiAnnualDate.getMonth() + 6 * unit,
        year: nextSemiAnnualDate.getFullYear()
    });
}

export function getNextAnnualDate(date, unit = 1) {
    const nextAnnualDate = new Date(date);
    nextAnnualDate.setFullYear(nextAnnualDate.getFullYear() + 1 * unit);
    return nextAnnualDate;
}

export function getLastDateOfTheMonth(year, month) {
    return new Date(year, month + 1, 0);
}

export function toValidDate({ day, month, year }) {
    const lastDateOfTheMonth = getLastDateOfTheMonth(year, month);
    const currentDate = new Date(year, month, day);

    return lastDateOfTheMonth.getDate() < day
        ? lastDateOfTheMonth
        : currentDate;
}

export function transformDateToBusinessDate(dueDate) {
    let validDueDate = new Date(dueDate);

    // Check if the new date falls on a weekend (Saturday: 6, Sunday: 0)
    if (validDueDate.getDay() === 6) {
        // Move to the prev Friday
        validDueDate.setDate(validDueDate.getDate() - 1);
    } else if (validDueDate.getDay() === 0) {
        // Move to the prev Friday
        validDueDate.setDate(validDueDate.getDate() - 2);
    }

    return validDueDate;
}

export function getFutureRecurrenceDate({
    startDate,
    recurrenceType,
    unit = 1
}) {
    const getNextDate =
        getNextDateGenerator[recurrenceType || defaultRecurrenceType];
    return getNextDate(startDate, unit);
}

export function getFutureRecurrenceDates({
    endDate,
    recurrenceType,
    startDate
}) {
    const recurrenceDates = [];
    let unit = 1;

    let currentRecurrenceDate = getFutureRecurrenceDate({
        recurrenceType,
        startDate,
        unit
    });

    while (currentRecurrenceDate && currentRecurrenceDate <= endDate) {
        unit += 1;
        recurrenceDates.push(currentRecurrenceDate);
        const futureDate = getFutureRecurrenceDate({
            recurrenceType,
            startDate,
            unit
        });

        if (futureDate <= currentRecurrenceDate) {
            throw new Error('Possible infinite loop');
        }

        currentRecurrenceDate = futureDate;
    }

    return recurrenceDates;
}

export function getLastDueDate({ dueDate, recurrenceType }) {
    const aYearInAdvance = getNextAnnualDate(new Date(dueDate));

    const getLastDueDate = {
        annually: aYearInAdvance,
        biweekly: aYearInAdvance,
        daily: getNextDayDate(dueDate, 28),
        monthly: aYearInAdvance,
        none: new Date(dueDate),
        quarterly: aYearInAdvance,
        semi_annual: aYearInAdvance,
        weekly: aYearInAdvance
    };

    const lastDueDate = getLastDueDate[recurrenceType || defaultRecurrenceType];

    if (!lastDueDate) {
        throw new Error('Invalid recurrence type');
    }

    return lastDueDate;
}

export function computeRecurrenceFutureDueDate({
    daysBeforeDueDate,
    dueDate,
    recurrences = [],
    recurrenceType
}) {
    const startDate = new Date(dueDate);

    const sortedRecurrences = [...recurrences].sort(
        (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
    );

    const nextRecurrenceDueDates = updateRecurrenceDates({
        daysBeforeDueDate,
        dueDate: startDate,
        recurrenceType,
        recurrences: sortedRecurrences
    }).map((recurrence, index) => ({
        futureDueDate: recurrence.dueDate,
        futureStartDate: recurrence.startDate,
        id: recurrence.id,
        originalDueDate: new Date(sortedRecurrences[index].dueDate),
        originalStartDate: new Date(sortedRecurrences[index].startDate)
    }));

    return nextRecurrenceDueDates;
}

export function updateRecurrenceDates({
    daysBeforeDueDate,
    dueDate,
    recurrences = [],
    recurrenceType
}) {
    const startDate = new Date(dueDate);
    const nextDueDates = generateUniqueRecurrenceDatesFromCount({
        count: recurrences.length,
        daysBeforeDueDate,
        dueDate: startDate,
        recurrenceType
    }).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

    const sortedRecurrences = [...recurrences].sort(
        (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
    );

    const nextRecurrenceDueDates = sortedRecurrences.map(
        (recurrence, index) => ({
            ...recurrence,
            ...nextDueDates[index]
        })
    );

    return nextRecurrenceDueDates;
}

function getRecurenceDates({ daysBeforeDueDate, dueDate }) {
    const futureDueDate = transformDateToBusinessDate(dueDate);
    const futureStartDate = addDaysToDate(futureDueDate, -daysBeforeDueDate);

    return {
        dueDate: futureDueDate,
        startDate: futureStartDate
    };
}

export function draftUniqueRecurrenceDates({
    daysBeforeDueDate,
    endDate,
    greaterThanDueDate,
    recurrenceType,
    startDate
}) {
    let nextRecurrenceDueDates = getFutureRecurrenceDates({
        endDate,
        recurrenceType,
        startDate
    });

    if (greaterThanDueDate) {
        const filterDate = new Date(greaterThanDueDate);
        nextRecurrenceDueDates = nextRecurrenceDueDates.filter(
            date => date > filterDate
        );
    }

    nextRecurrenceDueDates = nextRecurrenceDueDates.map(nextDueDate =>
        getRecurenceDates({ daysBeforeDueDate, dueDate: nextDueDate })
    );

    return uniqueRecurrenceDates({
        excludeDates: [startDate],
        recurrenceDates: nextRecurrenceDueDates
    });
}

function uniqueRecurrenceDates({
    excludeDates = [],
    recurrenceDates,
    key = 'dueDate'
}) {
    const uniqueRecurrenceDates = {};
    const uniqueDates = [];

    for (const recurrence of recurrenceDates) {
        const currentDate = new Date(recurrence[key]);
        if (isNaN(currentDate)) {
            throw new Error('Invalid date');
        }

        if (
            !uniqueRecurrenceDates[recurrence[key]] &&
            !excludeDates.some(
                date => new Date(date).getTime() === currentDate.getTime()
            )
        ) {
            uniqueDates.push(recurrence);
            uniqueRecurrenceDates[recurrence[key]] = true;
        }
    }

    return uniqueDates;
}

export function generateUniqueRecurrenceDatesFromCount({
    count,
    daysBeforeDueDate,
    dueDate,
    recurrenceType
}) {
    const recurrenceDates = [];

    if (count < 0) {
        throw new Error('Count must be a positive integer.');
    }

    // Helper function to generate a batch of recurrence dates
    const generateBatch = (startDate, countRemaining) =>
        draftUniqueRecurrenceDates({
            daysBeforeDueDate,
            endDate: getLastDueDate({ dueDate: startDate, recurrenceType }),
            recurrenceType,
            startDate
        }).slice(0, countRemaining);

    let currentStartDate = dueDate;

    while (recurrenceDates.length < count) {
        const remainingCount = count - recurrenceDates.length;
        const batch = generateBatch(currentStartDate, remainingCount);

        if (batch.length === 0) {
            break;
        }

        recurrenceDates.push(...batch);
        currentStartDate = batch[batch.length - 1].dueDate;
    }

    return recurrenceDates;
}
