import { useEffect, useState } from 'react';
import lodash from 'lodash';

import Chart from 'components/charts/ChartLoader';
import DynamicTableDropdowns from 'components/DynamicTableDropdowns';
import DynamicTableHeader from 'components/DynamicTableHeader';
import DynamicTableRow from 'components/DynamicTableRow';
import PanelBody from 'components/PanelBody';
import Table from 'components/Table';
import TableFooter from 'components/TableFooter';
import Modal from 'components/Modal';
import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';

import titleCase from 'utilities/titleCase';
import { useSetFilteredActivitiesData } from 'components/Activities/hooks/useFilteredActivities';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';
import stripHtml from 'utilities/stripHtml';

function containsObject(obj, list) {
    const objStr = JSON.stringify(obj, Object.keys(obj).sort());
    return list.some(
        item => JSON.stringify(item, Object.keys(item).sort()) === objStr
    );
}

const DynamicTable = ({ className = '', data, defaultCharts=[], hasCustomDateDropdown=false, hasMultitenant=false }) => {
    const setFilteredActivitiesData = useSetFilteredActivitiesData();

    const { useActivities } = useGlobalStateHooks();

    const [activities, _setActivities] = useActivities();

    const columnNames = Object.keys(data[0]);
    const normalizedColumnNames = columnNames.map(function(name){
        return titleCase(name)
    });
    
    
    const labelColumns = columnNames.filter(name => name !== 'dueDate' &&name !== 'startDate' && name !== 'createdDate' && name !== 'dateOfOccurrence' && name !== 'id')
    const [selectedColumns, _setSelectedColumns] = useState(columnNames);


    const [selectedDateFilter, setSelectedDateFilter] = useState(null);
    const [initialDateFilter, setInitialDateFilter] = useState("");    
    const [finalDateFilter, setFinalDateFilter] = useState("");
    const [customDatesInput, setCustomDatesInput] = useState({
        endDate: "",
        startDate: "",
    });

    // eslint-disable-next-line
    const [filters, setFilters] = useState(Array.apply(null, Array(labelColumns.length)).map(function () {}));
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, refreshData] = useState(false);

    const [charts, setCharts] = useState(defaultCharts);
    const [refreshCharts, setRefreshCharts] = useState(false);

    const [dropdownOptions, setDropdownOptions] = useState();
    const [openModal, setOpenModal] = useState(false);

    const applycustomDateFilter = () => {
        setInitialDateFilter(customDatesInput.startDate);
        setFinalDateFilter(customDatesInput.endDate);
        setOpenModal(false);
    }

    const formatDateToYYYYMMDD = (date) => {
        const d = new Date(date);
        let month = '' + (d.getUTCMonth() + 1),
            day = '' + d.getUTCDate(),
            year = d.getUTCFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const getQuarterDates = (currentDate, isCurrent) => {
        let year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        let quarter = Math.floor((month + 3) / 3);
    
        if (!isCurrent) {
            quarter = quarter - 1;
            if (quarter === 0) {
                quarter = 4;
                year = year - 1;
            }
        }
    
        const firstDayOfQuarter = new Date(Date.UTC(year, (quarter - 1) * 3, 1));
        const lastDayOfQuarter = new Date(Date.UTC(year, quarter * 3, 0));

        setCustomDatesInput({
            endDate: formatDateToYYYYMMDD(lastDayOfQuarter),
            startDate: formatDateToYYYYMMDD(firstDayOfQuarter),
        })
        
    
        return { end: lastDayOfQuarter, start: firstDayOfQuarter };
    };
    
    const getYearDates = (currentDate, isCurrent) => {
        const year = isCurrent ? currentDate.getFullYear() : currentDate.getFullYear() - 1;
        const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
        const lastDayOfYear = new Date(Date.UTC(year, 11, 31));

        setCustomDatesInput({
            endDate: formatDateToYYYYMMDD(lastDayOfYear),
            startDate: formatDateToYYYYMMDD(firstDayOfYear),
        })
    
        return { end: lastDayOfYear, start: firstDayOfYear };
    };

    const getDateRange = () => {
        const currentDate = new Date();
        switch (selectedDateFilter) {
            case 'all':
                return null;
            case 'thisQuarter':
                return getQuarterDates(currentDate, true);
            case 'lastQuarter':
                return getQuarterDates(currentDate, false);
            case 'thisYear':
                return getYearDates(currentDate, true);
            case 'lastYear':
                return getYearDates(currentDate, false);
            case 'custom':
                if (!customDatesInput.startDate && !customDatesInput.endDate) {
                    return null;
                }
                const startDate = customDatesInput.startDate ? new Date(customDatesInput.startDate) : new Date();
                const endDate = customDatesInput.endDate ? new Date(customDatesInput.endDate) : new Date();
                return { end: endDate, start: startDate };
            default:
                return null;
        }
    };

    const normalizedOptions = data.reduce((accumulator, options) => {
        for (var j = 0; j < labelColumns.length; j++) {
            var typeSort = false;
            if(accumulator.length <= j){
                accumulator.push([])
            }
            const currentOption = options[labelColumns[j]];
            if(typeof currentOption === 'string'){
                if(!containsObject({label: currentOption, value: currentOption}, accumulator[j])){
                    accumulator[j].push({label: stripHtml(currentOption), value: currentOption})
                }
            }else if(currentOption === null || currentOption === undefined){
                if(!containsObject({label:'null', value:'null'}, accumulator[j])){
                    accumulator[j].push({label:'null', value:'null'})
                }
            }else if(typeof currentOption === 'number' || typeof currentOption === 'boolean' || currentOption === null){
                typeSort = true;
                if(!containsObject({label:currentOption.toString(), value:currentOption}, accumulator[j])){
                    accumulator[j].push({label:currentOption.toString(), value:currentOption})
                }
            }else if(Array.isArray(currentOption)){
                for(const subOption of currentOption){
                    if(typeof subOption === 'string'){
                        if(!containsObject({label: subOption, value: subOption}, accumulator[j])){
                            accumulator[j].push({label: subOption, value: subOption})
                        }
                    }else if(subOption === null || subOption === undefined){
                        if(!containsObject({label:'null', value:'null'}, accumulator[j])){
                            accumulator[j].push({label:'null', value:'null'})
                        }
                    }else if(typeof subOption === 'number' || typeof subOption === 'boolean' || currentOption === null){
                        if(!containsObject({label:subOption.toString(), value:subOption}, accumulator[j])){
                            accumulator[j].push({label:subOption.toString(), value:subOption})
                        }
                    }else if(typeof subOption === 'object' && !Array.isArray(subOption) && subOption !== null){
                        if(Object.keys(subOption).includes('type') && Object.keys(subOption).includes('label') && Object.keys(subOption).includes('id')){
                            if(!containsObject(subOption, accumulator[j])){
                                accumulator[j].push(subOption);
                            }
                        }else{
                            if(Object.keys(subOption).includes('name')){
                                if(!containsObject({label:subOption['name'], value:subOption['name']}, accumulator[j])){
                                    accumulator[j].push({label:subOption['name'], value:subOption['name']})
                                }
                            }else if(Object.keys(subOption).includes('description')){
                                if(!containsObject({label:subOption['description'], value:subOption['description']}, accumulator[j])){
                                    accumulator[j].push({label:subOption['description'], value:subOption['description']})
                                }
                            }else if(Object.keys(subOption).includes('type')){
                                if(!containsObject({label:subOption['type'], value:subOption['type']}, accumulator[j])){
                                    accumulator[j].push({label:subOption['type'], value:subOption['type']})
                                }
                            }else{
                                if(typeof subOption[Object.keys(subOption)[0]] !== 'object'){
                                    if(!containsObject({label:subOption[Object.keys(subOption)[0]], value:subOption[Object.keys(subOption)[0]]}, accumulator[j])){
                                        accumulator[j].push({label:subOption[Object.keys(subOption)[0]], value:subOption[Object.keys(subOption)[0]]})
                                    }
                                }
                            }
                        }
                    }
                }
            }else if(typeof currentOption === 'object' && !Array.isArray(currentOption) && currentOption !== null){
                if(Object.keys(currentOption).includes('type') && Object.keys(currentOption).includes('label') && Object.keys(currentOption).includes('id')){
                    if(!containsObject({label:currentOption['label'], value:currentOption['id']}, accumulator[j])){
                        accumulator[j].push({label:currentOption['label'], value:currentOption['id']});
                    }
                }
            }
            if(typeSort){
                accumulator[j].sort((a,b)=> (b.type - a.type && a.label.localeCompare(b.label)));
            }else{
                accumulator[j].sort((a,b)=> (a.label - b.label));
            }
        }
        return accumulator
    },[])

    useEffect(() => {
        if (data) {
            var filteringData = lodash.cloneDeep(data);
            const filterEntries = Array.isArray(filters) ? filters.entries() : Object.entries(filters);
            for(const [index, filter] of filterEntries){
                const column = labelColumns[index];
                const isFilter = filter ? Array.isArray(filter) : false;
                const isFilterLength = isFilter ? filter.length > 0 : false;
                filteringData = isFilterLength
                    ? filteringData.filter(
                        (row) => {
                            if(column === 'createdBy'){
                                return filter.includes(row[column]?.id);
                            }else if(column === 'userActivities' || column === 'userCases'|| column === 'appliedTos'){
                                return filter.filter(el => {return row[column].find(element => {return element.label === el;});}).length > 0;
                            }else if(column === 'sections'){
                                return filter.filter(el => {return row[column].find(element => {return element.name === el;});}).length > 0;
                            }else{
                                return filter.includes(row[column]);
                            }
                        }
                    )
                    : filteringData;
            }

            for(const [index, row] of filteringData.entries()){
                const keys = Object.keys(row);
                for(const key of keys){
                    if(!selectedColumns.includes(key)){
                        delete filteringData[index][key]
                    }
                }
            }

            const dateRange = getDateRange();

            if (dateRange && (JSON.stringify(dateRange.start) !== "null" || JSON.stringify(dateRange.end) !== "null")) {
                filteringData = filteringData.filter((item) => {
                    let dueDate;
                    if(item.dueDate){
                        dueDate = new Date(item.dueDate);
                    } else if(item.createdDate){
                        dueDate = new Date(item.createdDate);
                    } else {
                        dueDate = new Date(item.dateOfOccurrence);
                    }
                    return dueDate >= dateRange.start && dueDate <= dateRange.end;
                });
            }

            const filteredDataWithoutDates = filteringData.map(row => {
                const keys = Object.keys(row);
                for (const key of keys) {
                    if ( key === 'startDate' || key === 'createdDate' || key === 'dateOfOccurrence') {
                        delete row[key];
                    }
                }
                return row;
            })

            setFilteredData(filteredDataWithoutDates);

            const columnDifference = labelColumns.filter(n => !selectedColumns.includes(n))
            if(columnDifference.length > 0){
                for(const column of columnDifference){
                    const deleteColumnIndex = labelColumns.indexOf(column);
                    const newOptionsArray = lodash.cloneDeep(normalizedOptions);
                    newOptionsArray.splice(deleteColumnIndex, 1);
                    setDropdownOptions(newOptionsArray);
                }
            }else{
                setDropdownOptions(normalizedOptions);
            }
            const columnIndexesToRemove = ['dueDate', 'startDate', 'createdDate', 'dateOfOccurrence', 'id'].map(column => labelColumns.indexOf(column)).filter(index => index !== -1);

            const newOptionsArray = normalizedOptions.filter((_, index) => !columnIndexesToRemove.includes(index));

            setDropdownOptions(newOptionsArray);
        }
        // eslint-disable-next-line
    }, [
        data,
        filters,
        finalDateFilter,
        initialDateFilter,
        refresh,
        refreshCharts,
        selectedColumns,
        setFilteredData,
        selectedDateFilter,
    ]);

    useEffect(() => {
        if (filteredData && activities && hasMultitenant) {
            const filteredActivitiesData = {
                completed: [],
                inProgress: [],
                overdue: [],
                upcoming: []
            };
        
            const allActivities = [
                ...activities.completed,
                ...activities.inProgress,
                ...activities.overdue,
                ...activities.upcoming
            ];
        
            filteredData.forEach(datum => {
                const activity = allActivities.find(activity => activity.id === datum.id);
                if (activity) {
                    const status = activity.status;
                    if (status === 'completed') {
                        filteredActivitiesData.completed.push(activity);
                    } else if (status === 'inprogress') {
                        filteredActivitiesData.inProgress.push(activity);
                    } else if (status === 'overdue') {
                        filteredActivitiesData.overdue.push(activity);
                    } else if (status === 'upcoming') {
                        filteredActivitiesData.upcoming.push(activity);
                    }
                }
            });
        
            setFilteredActivitiesData(filteredActivitiesData);
        }
    }, [filteredData, activities, setFilteredActivitiesData]);

    return (
        <>
            <Modal className={styles.modal} isOpen={openModal} onClose={() => setOpenModal(false)} overlayClose={true}>
                <div className={styles.dateCustomContainer}>
                    <p>Select dates:</p>

                    <div className={styles.dateCustomWrap}>
                        <div className={styles.dateCustomAddDates} key="initial-date-dropdown">
                            <p>Initial Date:</p>

                            <Input
                                onChange={(value) => setCustomDatesInput(prev => ({ ...prev, startDate: value }))}
                                type="date"
                                value={customDatesInput.startDate}
                            />
                        </div>

                        <div className={styles.dateCustomAddDates} key="final-date-dropdown">
                            <p>End Date:</p>

                            <Input
                                onChange={(value) => setCustomDatesInput(prev => ({ ...prev, endDate: value }))}
                                type="date"
                                value={customDatesInput.endDate}
                            />
                        </div>
                    </div>

                    <div className={styles.dateCustomAddDatesButtons}>
                        <SubmitButton
                            className={styles.dateCustomAddDatesButton}
                            onClick={applycustomDateFilter}
                        >
                            Apply
                        </SubmitButton>

                        <SubmitButton
                            className={styles.dateCustomAddDatesButton}
                            onClick={() => setOpenModal(false)}
                        >
                            Cancel
                        </SubmitButton>
                    </div>
                </div>
            </Modal>

            <PanelBody className={styles.panelBody}>

                <Table className={`${className} ${styles.caseTable}`}>
                    <div id={'capture'}>
                        <div className={styles.chartsWrapper}>
                            {charts.map(
                                ({alternativeTitle, column, label, type}) => (
                                    <Chart 
                                        alternativeTitle={alternativeTitle}
                                        className={className}
                                        column={Array.isArray(column) ? column : [column]}
                                        data={filteredData}
                                        key={`chart${alternativeTitle}`}
                                        label={label}
                                        type={type}
                                    />
                                )
                            )}
                        </div>

                    <DynamicTableHeader
                        charts={charts}
                        className={styles.tableHeader}
                        hasCustomDateDropdown={hasCustomDateDropdown}
                        items={normalizedColumnNames}
                        refreshCharts={refreshCharts}
                        selectedColumns={labelColumns}
                        setCharts={setCharts}
                        setRefreshCharts={setRefreshCharts}
                    />
                    
                    <DynamicTableDropdowns
                        className={styles.tableDropdowns}
                        filters={filters}
                        hasCustomDateDropdown={hasCustomDateDropdown}
                        items={dropdownOptions}
                        refresh={refresh}
                        refreshData={refreshData}
                        selectedDateFilter={selectedDateFilter}
                        setFilters={setFilters}
                        setOpenModal={setOpenModal}
                        setSelectedDateFilter={setSelectedDateFilter}
                    />
                </div>



                    {filteredData.map(
                        (datum, index) => (
                            <DynamicTableRow data={datum} hasCustomDateDropdown={hasCustomDateDropdown} key={`DynamicTableRow${index}`} />
                        )
                    )}

                    <TableFooter className={styles.tableFooter}></TableFooter>
                </Table>
            </PanelBody>
        </>
    );
};

export default DynamicTable;

