import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryLabels = labelSchema => gql`
    query getLabels {
        ${labelSchema}_labels{
            id
            label_name
            label_color
        }
    }
`;

const useLabelData = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(queryLabels(schema), { fetchPolicy: 'no-cache' });
};

export default useLabelData;
